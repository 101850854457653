import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Service.scss";

const Service = () => {
  return (
    <section className="medium-space">
      <Container>
        <Row>
          <Col xl={3} md={6} sm={6}>
            <div className="service-box">
              <img
                className="img-fluid service-icon"
                src="/images/svg-icons/truck.svg"
                alt="service-icon"
              />
              <div className="service-details">
                <h4 className="service-title">Fast Shipping</h4>
                <p>Fast Shipping World Wide</p>
              </div>
            </div>
          </Col>
          <Col xl={3} md={6} sm={6}>
            <div className="service-box">
              <img
                className="img-fluid service-icon"
                src="/images/svg-icons/service.svg"
                alt="service-icon"
              />
              <div className="service-details">
                <h4 className="service-title">24 X 7 Service</h4>
                <p>Online Service For 24 X 7</p>
              </div>
            </div>
          </Col>
          <Col xl={3} md={6} sm={6}>
            <div className="service-box">
              <img
                className="img-fluid service-icon"
                src="/images/svg-icons/offer.svg"
                alt="service-icon"
              />
              <div className="service-details">
                <h4 className="service-title">Festival Offer</h4>
                <p>New Online Special Festival Offer</p>
              </div>
            </div>
          </Col>
          <Col xl={3} md={6} sm={6}>
            <div className="service-box">
              <img
                className="img-fluid service-icon"
                src="/images/svg-icons/payment.svg"
                alt="service-icon"
              />
              <div className="service-details">
                <h4 className="service-title">Online Payment</h4>
                <p>New Online Special Festival Offer</p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Service;
