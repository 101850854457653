import React, { useState } from "react";
import "./Header.scss";
import { Container, Nav, Navbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

export const Header = (props) => {
  const [headerClassName, setHeaderClassName] = useState("");
  const handleScroll = (headerClassName) => {
    if (headerClassName !== "menuscroll" && window.pageYOffset >= 50) {
      setHeaderClassName("menuscroll");
    } else if (headerClassName === "menuscroll" && window.pageYOffset < 50) {
      setHeaderClassName("");
    }
  };

  React.useEffect(() => {
    window.onscroll = () => handleScroll(headerClassName);
  }, [headerClassName]);

  return (
    <header id="HeaderSection" className={headerClassName}>
      <Navbar expand="lg">
        <Container>
          <LinkContainer to="/home">
            <Navbar.Brand href="#">
              <img className="img-fluid" src="/images/logo.png" alt="img" />
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav>
              <LinkContainer to="/home">
                <Nav.Link className={props.hiddenNavLink}>Home</Nav.Link>
              </LinkContainer>
              <Nav.Link href="#TopBarSection" className={props.hiddenLNLink}>
                Home
              </Nav.Link>
              <Nav.Link href="#trendingSection" className={props.hiddenLNLink}>
                Product
              </Nav.Link>
              <Nav.Link href="#BenifitsSection" className={props.hiddenLNLink}>
                Benefits
              </Nav.Link>
              <Nav.Link href="#AboutSection" className={props.hiddenLNLink}>
                About Us
              </Nav.Link>
              <Nav.Link href="#ContactSection" className={props.hiddenLNLink}>
                Contact us
              </Nav.Link>
              <LinkContainer to="/listing-products">
                <Nav.Link href="#trendingSection">All Products</Nav.Link>
              </LinkContainer>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};
