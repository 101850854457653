import { Col, Container, Row, Form } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import BreadcrumbBox from "../../Components/BreadcrumbBox/BreadcrumbBox";
import ProductBox from "../../Components/ProductBox/ProductBox";
import { Header } from "../../Layout/Header/Header";
import "./ListingProducts.scss";
import satvaProduct from "../../../local-json/satvaProducts.json";
import Loader from "../../Components/Loader/Loader";
import Footer from "../../Layout/Footer/Footer";
import TopBar from "../../Components/TopBar/TopBar";

const ListingProducts = () => {
  const sProducts = satvaProduct.products;
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    setTimeout(() => setLoader(false), 2500);
  }, []);
  const [query, setQuery] = useState("");

  return (
    <div>
      {loader ? (
        <Loader />
      ) : (
        <div>
          <TopBar />
          <Header hiddenLNLink="HideNavLink" />
          <BreadcrumbBox removeItem={"hideItem"} activeItem={"disableItem"} />
          <section className="section-space">
            <Container>
              <Row>
                <Col lg={{ span: 6, offset: 3 }}>
                  <div className="search-panel">
                    <i class="fa-solid fa-magnifying-glass search-icon"></i>
                    <Form.Control
                      type="text"
                      placeholder="Search Product"
                      onChange={(event) => setQuery(event.target.value)}
                      className="custom-search"
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="listing-product-row">
                    {sProducts
                      .filter((post) => {
                        if (query === "") {
                          return post;
                        } else if (
                          post.title.toLowerCase().includes(query.toLowerCase())
                        ) {
                          return post;
                        }
                      })
                      .map((element) => {
                        return (
                          <div className="list-product" key={element.id}>
                            <ProductBox
                              listClass="plist-box"
                              productName={element.title}
                              productImage={element.images[0].image}
                              ProductDetailsName={`product-details/${element.id}`}
                            />
                          </div>
                        );
                      })}
                    {/* {sProducts &&
                      sProducts.map((element) => {
                        return (
                          <div className="list-product" key={element.id}>
                            <ProductBox
                              productName={element.title}
                              productImage={element.images[0].image}
                              ProductDetailsName={`product-details/${element.id}`}
                            />
                          </div>
                        );
                      })} */}
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <Footer />
        </div>
      )}
    </div>
  );
};

export default ListingProducts;
