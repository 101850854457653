import React from "react";
import "./Loader.scss";

const Loader = (props) => {
  return (
    <section className={`loader-section ${props.loaderClassName}`}>
      <div>
        <img
          src="/images/loader-logo.png"
          alt="loader-logo"
          className="rotate"
        />
        <div className="cs-loader">
          <div className="cs-loader-inner">
            <label>●</label>
            <label>●</label>
            <label>●</label>
            <label>●</label>
            <label>●</label>
            <label>●</label>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Loader;
