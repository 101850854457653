import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProductBox from "../ProductBox/ProductBox";
import Title from "../Title/Title";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper";
import { useNavigate } from "react-router-dom";
import "./TrendingProducts.scss";
import satvaProduct from "../../../local-json/satvaProducts.json";

const TrendingProducts = () => {
  const sProduct = satvaProduct.products;
  const ViewAllProducts = useNavigate();

  const navigateViewAllProducts = () => {
    ViewAllProducts("/listing-products");
  };
  return (
    <section id="trendingSection" className="section-space trending-space">
      <Container>
        <Row>
          <Col sm={12}>
            <Title titleText={`Trending Products`} />
          </Col>
          <Col sm={12}>
            <div className="trending-product-section">
              <Swiper
                navigation={true}
                modules={[Navigation, Autoplay]}
                spaceBetween={20}
                slidesPerView={1}
                autoplay={{
                  delay: 1500,
                  disableOnInteraction: false,
                }}
                breakpoints={{
                  1366: {
                    slidesPerView: 5,
                  },
                  991: {
                    slidesPerView: 4,
                  },
                  768: {
                    slidesPerView: 3,
                  },
                  576: {
                    slidesPerView: 2,
                  },
                }}
                // modules={[Pagination]}
                className="trending-swiper"
              >
                {sProduct.map((element) => {
                  return (
                    <SwiperSlide key={element.id}>
                      <ProductBox
                        productName={element.title}
                        productImage={element.images[0].image}
                        ProductDetailsName={`product-details/${element.id}`}
                      />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </Col>
          <Col sm={12}>
            <div className="view-product-box">
              <button
                className="primary-btn"
                onClick={navigateViewAllProducts}
                to="/listing-products"
              >
                View All Products <i className="fa-solid fa-arrow-right"></i>
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default TrendingProducts;
