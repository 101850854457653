import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./BenifitBanner.scss";

const BenifitBanner = () => {
  return (
    <section id="BenifitsSection" className="benifit-banner">
      <Container>
        <Row>
          <Col md={{ span: 10, offset: 1 }} className="p-0">
            <div className="benifit-section">
              <img
                className="img-fluid desktop-benifit"
                src="/images/benifit-points.png"
                alt="Bag-banner"
              />
              <img
                className="img-fluid mobile-benifit"
                src="/images/mobile-benifit.png"
                alt="Bag-banner"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default BenifitBanner;
