import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ContactForm from "../ContactForm/ContactForm";
import Title from "../Title/Title";
import "./ContactUs.scss";

const ContactUs = () => {
  return (
    <section id="ContactSection" className="contactus-section section-space">
      <Container>
        <Row>
          <Col xl={6} lg={8} md={12}>
            <Title titleText="CONTACT INFORMATION" customTitle="about-title" />
            <ContactForm
              customclassName="contactus-f"
              hideSelectedValue="d-none"
            />
          </Col>
        </Row>
      </Container>
      <img
        className="contactus-img"
        src="/images/contact-us-girl.png"
        alt="contact-us"
      />
    </section>
  );
};

export default ContactUs;
