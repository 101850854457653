import React, { useState, useEffect } from "react";
import "./Home.scss";
import MainBanner from "../../Components/MainBanner/MainBanner";
import Service from "../../Components/Services/Service";
import TrendingProducts from "../../Components/TrendingProducts/TrendingProducts";
import BagBanner from "../../Components/DesignerBagBanner/BagBanner";
import CategoryProducts from "../../Components/CategoryProducts/CategoryProducts";
import BenifitBanner from "../../Components/BenifitBanner/BenifitBanner";
import TeamMembers from "../../Components/TeamMembers/TeamMembers";
import CompostableBanner from "../../Components/CompostableBanner/CompostableBanner";
import AboutUs from "../../Components/AboutUs/AboutUs";
import ContactUs from "../../Components/ContactUs/ContactUs";
import { Header } from "../../Layout/Header/Header";
import Loader from "../../Components/Loader/Loader";
import Footer from "../../Layout/Footer/Footer";
import TopBar from "../../Components/TopBar/TopBar";

const Home = () => {
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    setTimeout(() => setLoader(false), 3000);
  }, []);
  return (
    <div>
      {loader ? (
        <Loader />
      ) : (
        <div>
          <TopBar />
          <Header hiddenNavLink="HideNavLink" />
          <MainBanner />
          <Service />
          <TrendingProducts />
          <BagBanner />
          <CategoryProducts />
          <BenifitBanner />
          <TeamMembers />
          <CompostableBanner />
          <AboutUs />
          <ContactUs />
          <Footer />
        </div>
      )}
    </div>
  );
};

export default Home;
