import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import axios from "axios";
import "./ContactForm.scss";
import satvaProduct from "../../../local-json/satvaProducts.json";

const ContactForm = (props) => {
  const sProducts = satvaProduct.products;
  const selectedProduct = props.selectedProduct;
  const selectedTrue = props.selectedTrue;

  const [state, setState] = useState({
    name: "",
    phone: "",
    email: "",
    product: selectedTrue ? selectedProduct : "",
    message: "",
  });

  const [result, setResult] = useState(null);

  const sendEmail = (event) => {
    event.preventDefault();
	  let formData = new FormData();
      formData.append('name', state.name)
      formData.append('email', state.email)
      formData.append('product',state.product)
      formData.append('phone', state.phone)
      formData.append('message', state.message)
      axios({
          method: 'POST',
          url: 'https://satvapackaging.com/contact.php',
          data: formData,
      })
      .then((response) => {
        setResult(response.data);
        setState({
          name: "",
          phone: "",
          email: "",
          product: "",
          message: "",
        });
      })
      .catch(() => {
        setResult({
          success: false,
          message: "Something went wrong. Try again later",
        });
      });
  };

  const onInputChange = (event) => {
    const { name, value } = event.target;

    setState({
      ...state,
      [name]: value,
    });
  };

  return (
    <>
      {result && (
        <p className={`${result.success ? "success" : "error"}`}>
          {result.message}
        </p>
      )}
      <Form
        className={`contact-form ${props.customclassName}`}
        onSubmit={sendEmail}
      >
        <Row className="custom-form-group">
          <Form.Group as={Col} controlId="Fullname">
            <Form.Label>Full name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Full Name"
              required
              name="name"
              value={state.name}
              onChange={onInputChange}
            />
          </Form.Group>

          <Form.Group as={Col} controlId="phoneNo">
            <Form.Label>Phone No</Form.Label>
            <Form.Control
              type="text"
              placeholder="Phone No"
              required
              onChange={onInputChange}
              name="phone"
              value={state.phone}
            />
          </Form.Group>
        </Row>
        <Form.Group className="custom-form-group" controlId="Email">
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter Address"
            required
            name="email"
            onChange={onInputChange}
            value={state.email}
          />
        </Form.Group>

        {/* <Form.Group
          className={`custom-form-group ${props.hideSelectedValue}`}
          controlId="Selected-Product"
        >
          <Form.Label>Selected Product</Form.Label>
          <Form.Control
            type="text"
            required
            placeholder={selectedProduct}
            onChange={onInputChange}
            defaultValue={selectedProduct}
            name="product"
            value={state.product}
          />
        </Form.Group> */}

        {/* props.hideMultiSelect */}
        {selectedTrue ? (
          <Form.Group
            className={`custom-form-group ${props.hideSelectedValue}`}
            controlId="Selected-Product"
          >
            <Form.Label>Selected Product</Form.Label>
            <Form.Control
              type="text"
              required
              disabled
              placeholder={selectedProduct}
              onChange={onInputChange}
              defaultValue={selectedProduct}
              name="product"
              value={state.product}
            />
          </Form.Group>
        ) : (
          <Form.Group className="custom-form-group" controlId="SelectProduct">
            <Form.Label>Select Product</Form.Label>
            <Form.Select
              required
              defaultValue={selectedProduct}
              name="product"
              value={state.product}
              onChange={onInputChange}
            >
              <option>Choose...</option>
              {sProducts.map((element) => {
                return <option value={element.title}>{element.title}</option>;
              })}
            </Form.Select>
          </Form.Group>
        )}

        <Form.Group className="custom-form-group" controlId="Message">
          <Form.Label>Message</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Type Short Message"
            name="message"
            value={state.message}
            onChange={onInputChange}
            required
          />
        </Form.Group>

        <Form.Group className="custom-form-group submit-btns">
          <Button type="submit" className="primary-btn">
            Submit <i className="fa-solid fa-arrow-right"></i>
          </Button>
        </Form.Group>
      </Form>
    </>
  );
};

export default ContactForm;
