import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import BreadcrumbBox from "../../Components/BreadcrumbBox/BreadcrumbBox";
import { Header } from "../../Layout/Header/Header";
import "./ProductDetails.scss";
import satvaProduct from "../../../local-json/satvaProducts.json";
import ContactForm from "../../Components/ContactForm/ContactForm";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import Footer from "../../Layout/Footer/Footer";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper";
import Loader from "../../Components/Loader/Loader";
import TopBar from "../../Components/TopBar/TopBar";

const ProductDetails = () => {
  const sProducts = satvaProduct.products;
  const id = useParams();
  const conTrue = true;

  const [details, setDetails] = useState([]);

  useEffect(() => {
    sProducts.map((item) => {
      if (item.id === id.productId) {
        return setDetails(item);
      }
    });
  }, [id]);
  const [customSwiper, setCustomSwiper] = useState(null);
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    setTimeout(() => setLoader(false), 2500);
  }, []);

  return (
    <div>
      {loader ? (
        <Loader />
      ) : (
        <div>
          <TopBar />
          <Header hiddenLNLink="HideNavLink" />
          <BreadcrumbBox BreadcrumbName={details.title} />
          <section className="section-space">
            <Container>
              <Row>
                <Col lg={6}>
                  <section className="product-slider">
                    <Swiper
                      spaceBetween={0}
                      navigation={true}
                      thumbs={{ swiper: customSwiper }}
                      modules={[FreeMode, Navigation, Thumbs]}
                      className="main-slider"
                    >
                      {details.images?.map((imgItem) => {
                        return (
                          <SwiperSlide key={imgItem.image}>
                            <img
                              className="img-fluid main-slider-img"
                              alt={imgItem.image}
                              src={imgItem.image}
                            />
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                    <Swiper
                      onSwiper={setCustomSwiper}
                      spaceBetween={20}
                      slidesPerView={3}
                      freeMode={true}
                      watchSlidesProgress={true}
                      modules={[FreeMode, Navigation, Thumbs]}
                      breakpoints={{
                        768: {
                          slidesPerView: 4,
                        },
                        576: {
                          slidesPerView: 3,
                        },
                      }}
                      className="nav-slider"
                    >
                      {details.images?.map((imgItem) => {
                        return (
                          <SwiperSlide key={imgItem.image}>
                            <img
                              className="img-fluid nav-slider-img"
                              alt={imgItem.image}
                              src={imgItem.image}
                            />
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </section>
                </Col>
                <Col lg={6}>
                  <section className="product-details-section">
                    <div className="product-data">
                      <h2 className="product-title">{details.title}</h2>
                      <p className="product-details">{details.description}</p>
                    </div>
                    <div className="product-form">
                      <h3 className="product-form-title">
                        Product Enquiry Form
                      </h3>
                      <ContactForm
                        selectedProduct={details.title}
                        selectedTrue={conTrue}
                        productId={details.id}
                      />
                    </div>
                  </section>
                </Col>
              </Row>
            </Container>
          </section>
          <Footer />
        </div>
      )}
    </div>
  );
};

export default ProductDetails;
