import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./BagBanner.scss";

const BagBanner = () => {
  return (
    <Container fluid>
      <Row>
        <Col sm={12} className="p-0">
          <div className="bag-banner">
            <img
              className="img-fluid"
              src="/images/designer-bag-banner.jpg"
              alt="Bag-banner"
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default BagBanner;
