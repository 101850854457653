import React from "react";
import Card from "react-bootstrap/Card";
import "./TeamCard.scss";

const TeamCard = (props) => {
  return (
    <Card className="team-member-card">
      <Card.Img src={props.memberImage} alt={props.memberName} />
      <Card.Body>
        <h5 className="member-position">{props.memberPosition}</h5>
        <Card.Title>{props.memberName}</Card.Title>
        {/* <div className="members-links">
          <ul>
            <li>
              <NavLink className="social-links" href={props.facebook}>
                <i className="fa-brands fa-facebook-f"></i>
              </NavLink>
            </li>
            <li>
              <NavLink className="social-links" href={props.insta}>
                <i className="fa-brands fa-instagram"></i>
              </NavLink>
            </li>
            <li>
              <NavLink className="social-links" href={props.linkid}>
                <i className="fa-brands fa-linkedin-in"></i>
              </NavLink>
            </li>
            <li>
              <NavLink className="social-links" href={props.twitter}>
                <i className="fa-brands fa-twitter"></i>
              </NavLink>
            </li>
          </ul>
        </div> */}
      </Card.Body>
    </Card>
  );
};

export default TeamCard;
