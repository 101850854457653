import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
import "./BreadcrumbBox.scss";

const BreadcrumbBox = (props) => {
  return (
    <section className="breadcrumb-section">
      <Container>
        <Row>
          <Col sm={12}>
            <Breadcrumb className="breadcrumb-box">
              <Breadcrumb.Item>
                <Link to={"/home"}>Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active className={props.activeItem}>
                <Link to={"/listing-products"}>All Products</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active className={props.removeItem}>
                {props.BreadcrumbName}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
      </Container>
      <img className="img-fluid leaf-img" src="/images/leaf.png" alt="" />
    </section>
  );
};

export default BreadcrumbBox;
