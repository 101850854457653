import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import ListingProducts from "./common/Pages/ListingProducts/ListingProducts";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./common/Pages/Home/Home";
import Layout from "./common/Layout/Layout";
import ProductDetails from "./common/Pages/ProductDetails/ProductDetails";
import NotFoundPage from "./common/Pages/NotFoundPage/NotFoundPage";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/listing-products" element={<ListingProducts />} />
          <Route
            path="/product-details/:productId"
            element={<ProductDetails />}
          />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
