import React, { useEffect, useMemo, useState } from "react";
import { Container, Row, Col, Nav, Tab } from "react-bootstrap";
import ProductBox from "../ProductBox/ProductBox";
import Title from "../Title/Title";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { Pagination } from "swiper";
import satvaProduct from "../../../local-json/satvaProducts.json";
import { useNavigate } from "react-router-dom";
import "./CategoryProducts.scss";

const CategoryProducts = () => {
  const ViewAllProducts = useNavigate();
  const navigateViewAllProducts = () => {
    ViewAllProducts("/listing-products");
  };

  const sProducts = satvaProduct.products;

  const [categoryList, setCategoryList] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState();

  // Add default value on page load
  useEffect(() => {
    setCategoryList(sProducts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Function to get filtered list
  function getFilteredList() {
    // Avoid filter when selectedCategory is null
    if (!selectedCategory) {
      return categoryList;
    }
    return categoryList.filter((item) => {
      return item.category === selectedCategory;
    });
  }

  // Avoid duplicate function calls with useMemo
  var filteredList = useMemo(getFilteredList, [selectedCategory, categoryList]);

  function handleCategoryChange(event) {
    setSelectedCategory(event.target.name);
  }

  return (
    <section className="section-space">
      <Container>
        <Row>
          <Col sm={12}>
            <Title titleText={`Shop by category`} />
          </Col>
          <Col sm={12}>
            <Tab.Container defaultActiveKey="first">
              <Row>
                <Col sm={12}>
                  <Nav variant="pills" className="tab-product-nav">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="first"
                        name="Paper Bag"
                        onClick={handleCategoryChange}
                      >
                        Paper bags
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="second"
                        name="Sugercane Bagasse Product"
                        onClick={handleCategoryChange}
                      >
                        Sugercane Bagasse Product
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="third"
                        name="Paper Pouch"
                        onClick={handleCategoryChange}
                      >
                        Paper Pouch
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="fourth"
                        name="Wooden Cutlery"
                        onClick={handleCategoryChange}
                      >
                        Wooden Cutlery
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="five"
                        name="Ripple Paper Cup"
                        onClick={handleCategoryChange}
                      >
                        Ripple Paper Cup
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={12}>
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <div className="trending-product-section">
                        <Swiper
                          slidesPerView={1}
                          navigation={true}
                          modules={[Navigation, Pagination]}
                          spaceBetween={20}
                          pagination={{
                            clickable: true,
                          }}
                          className="trending-swiper"
                          breakpoints={{
                            1366: {
                              slidesPerView: 5,
                            },
                            991: {
                              slidesPerView: 4,
                            },
                            768: {
                              slidesPerView: 3,
                            },
                            576: {
                              slidesPerView: 2,
                            },
                          }}
                        >
                          {filteredList.map((element) => {
                            return (
                              <SwiperSlide key={element.id}>
                                <ProductBox
                                  productName={element.title}
                                  productImage={element.images[0].image}
                                  ProductDetailsName={`product-details/${element.id}`}
                                />
                              </SwiperSlide>
                            );
                          })}
                        </Swiper>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <div className="trending-product-section">
                        <Swiper
                          slidesPerView={1}
                          navigation={true}
                          modules={[Navigation, Pagination]}
                          spaceBetween={20}
                          pagination={{
                            clickable: true,
                          }}
                          className="trending-swiper"
                          breakpoints={{
                            1366: {
                              slidesPerView: 5,
                            },
                            991: {
                              slidesPerView: 4,
                            },
                            768: {
                              slidesPerView: 3,
                            },
                            576: {
                              slidesPerView: 2,
                            },
                          }}
                        >
                          {filteredList.map((element) => {
                            return (
                              <SwiperSlide key={element.id}>
                                <ProductBox
                                  productName={element.title}
                                  productImage={element.images[0].image}
                                  ProductDetailsName={`product-details/${element.id}`}
                                />
                              </SwiperSlide>
                            );
                          })}
                        </Swiper>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <div className="trending-product-section">
                        <Swiper
                          slidesPerView={1}
                          navigation={true}
                          modules={[Navigation, Pagination]}
                          spaceBetween={20}
                          pagination={{
                            clickable: true,
                          }}
                          className="trending-swiper"
                          breakpoints={{
                            1366: {
                              slidesPerView: 5,
                            },
                            991: {
                              slidesPerView: 4,
                            },
                            768: {
                              slidesPerView: 3,
                            },
                            576: {
                              slidesPerView: 2,
                            },
                          }}
                        >
                          {filteredList.map((element) => {
                            return (
                              <SwiperSlide key={element.id}>
                                <ProductBox
                                  productName={element.title}
                                  productImage={element.images[0].image}
                                  ProductDetailsName={`product-details/${element.id}`}
                                />
                              </SwiperSlide>
                            );
                          })}
                        </Swiper>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="fourth">
                      <div className="trending-product-section">
                        <Swiper
                          slidesPerView={1}
                          navigation={true}
                          modules={[Navigation, Pagination]}
                          spaceBetween={20}
                          pagination={{
                            clickable: true,
                          }}
                          className="trending-swiper"
                          breakpoints={{
                            1366: {
                              slidesPerView: 5,
                            },
                            991: {
                              slidesPerView: 4,
                            },
                            768: {
                              slidesPerView: 3,
                            },
                            576: {
                              slidesPerView: 2,
                            },
                          }}
                        >
                          {filteredList.map((element) => {
                            return (
                              <SwiperSlide key={element.id}>
                                <ProductBox
                                  productName={element.title}
                                  productImage={element.images[0].image}
                                  ProductDetailsName={`product-details/${element.id}`}
                                />
                              </SwiperSlide>
                            );
                          })}
                        </Swiper>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="five">
                      <div className="trending-product-section">
                        <Swiper
                          slidesPerView={1}
                          navigation={true}
                          modules={[Navigation, Pagination]}
                          spaceBetween={20}
                          pagination={{
                            clickable: true,
                          }}
                          className="trending-swiper"
                          breakpoints={{
                            1366: {
                              slidesPerView: 5,
                            },
                            991: {
                              slidesPerView: 4,
                            },
                            768: {
                              slidesPerView: 3,
                            },
                            576: {
                              slidesPerView: 2,
                            },
                          }}
                        >
                          {filteredList.map((element) => {
                            return (
                              <SwiperSlide key={element.id}>
                                <ProductBox
                                  productName={element.title}
                                  productImage={element.images[0].image}
                                  ProductDetailsName={`product-details/${element.id}`}
                                />
                              </SwiperSlide>
                            );
                          })}
                        </Swiper>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Col>
          <Col sm={12}>
            <div className="view-product-box">
              <button
                className="primary-btn"
                onClick={navigateViewAllProducts}
                to="/listing-products"
              >
                View All Products <i className="fa-solid fa-arrow-right"></i>
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default CategoryProducts;
