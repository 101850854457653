import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./TopBar.scss";

const TopBar = () => {
  return (
    <section id="TopBarSection">
      <Container>
        <Row>
          <Col sm={12}>
            <div className="topbar">
              <h5 className="welcome-text">
                Welcome to Our store Satva Packaging
              </h5>
              <div className="call-info">
                <i className="fa-solid fa-phone"></i>
                <h5>
                  Call Us : <span>+91 88664 88404</span>
                </h5>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default TopBar;
