import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./CompostableBanner.scss";

const CompostableBanner = () => {
  return (
    <Container fluid>
      <Row>
        <Col sm={12} className="p-0">
          <div className="compostable-banner">
            <img
              className="img-fluid"
              src="/images/compostable-banner.png"
              alt="compostable-banner"
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default CompostableBanner;
