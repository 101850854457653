import React from "react";
import Button from "react-bootstrap/Button";
import "./NotFoundPage.scss";
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
  const ViewAllProducts = useNavigate();
  const navigateViewAllProducts = () => {
    ViewAllProducts("/home");
  };
  return (
    <section className="section-space notfound-main-section">
      <div className="notfound-sec">
        <h2 className="notfound-title">404</h2>
        <img
          alt="404"
          className="img-fluid notfound-img"
          src="/images/404.png"
        />
        <div className="notfound-text-box">
          <h3>We can't find that page</h3>
          <p>
            We're fairly sure that page used to be here, but seems to have gone
            missing. We do apologise on it's behalf.
          </p>
        </div>
        <Button
          type="submit"
          className="primary-btn"
          onClick={navigateViewAllProducts}
        >
          Go To Home <i className="fa-solid fa-arrow-right"></i>
        </Button>
      </div>
    </section>
  );
};

export default NotFoundPage;
