import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-bootstrap";
import "./Footer.scss";

const Footer = () => {
  return (
    <div>
      <footer className="section-space">
        <Container>
          <Row xs={{ gutterX: 5 }}>
            <Col lg={4}>
              <div className="footer-box">
                <img
                  className="img-fluid footer-logo"
                  src="/images/logo.png"
                  alt="footer-logo"
                />
                <p className="footer-brand-details">
                  Satva focuses on maintaining best suitable solutions for
                  developing new or maintaining business venture and ensuring
                  its sustainability and longevity.
                </p>
                <div className="members-links">
                  <ul>
                    <li>
                      <NavLink
                        className="social-links"
                        href="https://www.facebook.com/satvapackaging/"
                        target="_blank"
                      >
                        <i className="fa-brands fa-facebook-f"></i>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className="social-links"
                        href="https://www.instagram.com/satvapackaging/"
                        target="_blank"
                      >
                        <i className="fa-brands fa-instagram"></i>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className="social-links"
                        href="https://www.linkedin.com/in/satva-packaging-909a7b264/"
                        target="_blank"
                      >
                        <i className="fa-brands fa-linkedin-in"></i>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="social-links" href="#">
                        <i className="fa-brands fa-twitter"></i>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="footer-box footer-center-box">
                <div className="footer-title">
                  <h4>STORE INFORMATION</h4>
                </div>
                <ul className="footer-contact">
                  <li>
                    <i className="fa-solid fa-map-location-dot"></i>
                    <label>
                      Satva Packaging, Naranji mulji complex, 2803 libarty road,
                      Porbandar-360575 , Gujarat
                    </label>
                  </li>
                  <li>
                    <i className="fa-solid fa-phone"></i>
                    <label>Mobile No : +91 8866488404</label>
                  </li>
                  <li>
                    <i className="fa-solid fa-envelope-open-text"></i>
                    <label>Email Us : satvapackagings@gmail.com</label>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={4}>
              <div className="footer-box">
                <div className="footer-title">
                  <h4>LOCATION</h4>
                </div>
                <div className="footer-map">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3708.6296796477745!2d69.60428371494308!3d21.639349385670855!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjHCsDM4JzIxLjciTiA2OcKwMzYnMjMuMyJF!5e0!3m2!1sen!2sin!4v1678005579556!5m2!1sen!2sin"
                    title="This is a address map"
                    allowfullscreen=""
                    style={{ border: 0 }}
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
      <div className="copyright-box">
        <h6>Copyright © 2022 by SATVA | ISO Certified</h6>
      </div>
    </div>
  );
};

export default Footer;
