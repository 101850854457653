import React from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { LinkContainer } from "react-router-bootstrap";
import "./ProductBox.scss";

const ProductBox = (props) => {
  return (
    <Card className="product-card">
      <Card.Img src={props.productImage} className={props.listClass} />
      <Card.Body>
        <Card.Title>{props.productName}</Card.Title>
        <LinkContainer to={`/${props.ProductDetailsName}`}>
          <Button className="card-btn">View Product</Button>
        </LinkContainer>
      </Card.Body>
    </Card>
  );
};

export default ProductBox;
