import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import { Navigation, Autoplay, EffectFade } from "swiper";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./MainBanner.scss";

const MainBanner = () => {
  const ViewAllProducts = useNavigate();

  const navigateViewAllProducts = () => {
    ViewAllProducts("/listing-products");
  };

  return (
    <section id="MainBannerSection">
      <Swiper
        navigation={true}
        modules={[EffectFade, Autoplay, Navigation]}
        centeredSlides={true}
        effect={"fade"}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: true,
        }}
        className="satva-swiper"
      >
        <SwiperSlide>
          <div className="main-banner banner1">
            <img
              className="img-fluid product-banner-img"
              alt="product-banner-img"
              src="/images/bag-banner1.png"
            />
            <Container>
              <Row>
                <Col lg={{ span: 6, offset: 6 }}>
                  <div className="text-position">
                    <div className="banner-text">
                      <div className="banner-title">
                        <h4 className="sub-title">How to go</h4>
                        <h2 className="tag-title">Zero Waste?</h2>
                      </div>
                      <p className="banner-details">
                        Switch to natural items instead of chemical products.
                      </p>
                      <button
                        className="primary-btn"
                        onClick={navigateViewAllProducts}
                        to="/listing-products"
                      >
                        View All Products{" "}
                        <i className="fa-solid fa-arrow-right"></i>
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="main-banner banner1">
            <img
              className="img-fluid product-banner-img"
              src="/images/bag-banner2.png"
              alt="product-banner-img"
            />
            <Container>
              <Row>
                <Col lg={{ span: 6, offset: 6 }}>
                  <div className="text-position">
                    <div className="banner-text">
                      <div className="banner-title">
                        <h4 className="sub-title">Compostable</h4>
                        <h2 className="tag-title">Eco-Friendly Tableware</h2>
                      </div>
                      <p className="banner-details">
                        With Satva Lets make every day earth day!
                      </p>
                      <button
                        className="primary-btn"
                        onClick={navigateViewAllProducts}
                        to="/listing-products"
                      >
                        View All Products{" "}
                        <i className="fa-solid fa-arrow-right"></i>
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="main-banner banner1">
            <img
              className="img-fluid product-banner-img"
              src="/images/bag-banner3.png"
              alt="product-banner-img"
            />
            <Container>
              <Row>
                <Col lg={{ span: 6, offset: 6 }}>
                  <div className="text-position">
                    <div className="banner-text">
                      <div className="banner-title">
                        <h4 className="sub-title">Think Green</h4>
                        <h2 className="tag-title">Save theFuture</h2>
                      </div>
                      <p className="banner-details">
                        With Satva Lets make every day earth day! Use Eco Bags
                      </p>
                      <button
                        className="primary-btn"
                        onClick={navigateViewAllProducts}
                        to="/listing-products"
                      >
                        View All Products{" "}
                        <i className="fa-solid fa-arrow-right"></i>
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default MainBanner;
