import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import TeamCard from "../TeamCard/TeamCard";
import Title from "../Title/Title";
import satvaTeamMembers from "../../../local-json/satvaTeamMembers.json";

const TeamMembers = () => {
  const sTeamMembers = satvaTeamMembers.members;

  return (
    <section className="section-space">
      <Container>
        <Row>
          <Col md={12}>
            <Title titleText={`Team Members`} />
          </Col>
          {sTeamMembers.map((element) => {
            return (
              <Col xl={3} md={6} sm={6} className="team-mem">
                <TeamCard
                  memberImage={element.image}
                  memberName={element.name}
                  memberPosition={element.position}
                />
              </Col>
            );
          })}
        </Row>
      </Container>
    </section>
  );
};

export default TeamMembers;
