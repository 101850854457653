import React from "react";
import "./Title.scss";

const Title = (props) => {
  return (
    <div className={`title-box ${props.customTitle}`}>
      <h3 className="title-text">
        <span>{props.titleText}</span>
      </h3>
    </div>
  );
};

export default Title;
