import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Title from "../Title/Title";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper";
import "./AboutUs.scss";

const AboutUs = () => {
  return (
    <section id="AboutSection" className="section-space about-us-section">
      <Container>
        <Row>
          <Col xl={6}>
            <Swiper
              navigation={true}
              modules={[Autoplay, Navigation]}
              spaceBetween={0}
              slidesPerView={1}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              className="aboutus-swiper"
            >
              <SwiperSlide>
                <img
                  className="img-fluid aboutus-img"
                  src="/images/aboutus-1.jpg"
                  alt="swiper-img"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="img-fluid aboutus-img"
                  src="/images/aboutus-2.png"
                  alt="swiper-img"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="img-fluid aboutus-img"
                  src="/images/aboutus-3.png"
                  alt="swiper-img"
                />
              </SwiperSlide>
            </Swiper>
          </Col>
          <Col xl={6}>
            <div className="aboutus-text">
              <Title titleText="About us" customTitle="about-title" />
              <p>
                The word “Satva” means quality of balance, harmony, goodness,
                purity, creative, positive attitude, being-ness and peaceful.
                The name itself implies the motto for our company which looks
                forward to bring eco-friendly products to consumer which can
                allow them to maintain balance on mother earth. Satva offers
                great varieties of products such as paper bags, paper pouches,
                glasses and sugarcane bagasse products such as different
                varieties of plates, bowls, cups and food containers. These
                products allows our consumer to replace anti-green products
                which are less hazardous to environment. Satva focuses on
                maintaining best suitable solutions for developing new or
                maintaining business venture and ensuring its sustainability and
                longevity. Our mission and vision for Satva is limitless as
                there are still people out there who are not aware of our
                diminishing ecosystem. We are on a journey to habituate them and
                change their perspective about eco-friendly products for day to
                day use.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutUs;
